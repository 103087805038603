import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  MenuItem,
  Snackbar,
  Alert,
  Typography,
  IconButton,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { createPipeline, PipelineData } from "../services/documentService";
interface NewProjectModalProps {
  open: boolean;
  onClose: () => void;
  onCreate: (
    newPipeline: PipelineData,
    questionnaireData: Record<string, number>
  ) => void;
}

interface QuestionnaireDataType {
  [key: string]: {
    CONTENT: string;
    INSTRUCTION: string;
    NOTES?: string;
    MAPPING: Record<string, string[]>;
    MAPPING_NOTES?: string;
  };
}

const QuestionnaireData: QuestionnaireDataType = {
  "0": {
    CONTENT:
      "Is this application intended for a new drug or biological drug that is used in a clinical investigation to be conducted in the United States?",
    INSTRUCTION: "If no, stop the process. If not sure, contact us.",
    MAPPING: {},
  },
  "1": {
    CONTENT:
      "Is this the first in human clinical investigation for the product?",
    INSTRUCTION:
      "If yes, no need for Module 2.7 in general. If no, need to have placeholders for Module 2.7 sections, Module 5 corresponding sections, and certain M4 sections.",
    NOTES: "Suggest we focus on FIH IND which is simpler.",
    MAPPING: {
      "0": ["-2.7.1", "-2.7.2", "-2.7.3", "-2.7.4", "-2.7.6"],
      "1": ["+2.7.1", "+2.7.2", "+2.7.3", "+2.7.4", "+2.7.6"],
    },
    MAPPING_NOTES: "",
  },
  "2": {
    CONTENT:
      "Is the product small molecule or large molecule candidate (MW > 1000daltons).",
    INSTRUCTION: "If large molecule, no need for 4.2.3.3 Genotoxicity in M4",
    NOTES: "",
    MAPPING: {
      "0": ["-4.2.3.3"],
      "1": ["+4.2.3.3"],
    },
    MAPPING_NOTES: "Small Molecule: 0; Large Molecule: 1",
  },
  "3": {
    CONTENT:
      "Is the product indicated for life threatening disease with short life expectancy such as cancer?",
    INSTRUCTION:
      "If yes, no need for 4.2.3.4 Carcinogenicity, place study protocol under 5.3.3.2.",
    NOTES: "",
    MAPPING: {
      "0": ["+4.2.3.4", "-5.3.3.2"],
      "1": ["-4.2.3.1", "+5.3.3.2"],
    },
    MAPPING_NOTES: "",
  },
  "4": {
    CONTENT:
      "Does this IND need to cross-reference other applications which are not owned by your entity?",
    INSTRUCTION: "If yes, add 1.4.1 and 1.4.2",
    NOTES: "",
    MAPPING: {
      "0": ["-1.4.1", "-1.4.2"],
      "1": ["+1.4.1", "+1.4.2"],
    },
    MAPPING_NOTES: "",
  },
  "5": {
    CONTENT:
      "Does this IND need to cross-reference other applications which are owned by your entity?",
    INSTRUCTION: "If yes, add 1.4.4",
    NOTES: "",
    MAPPING: {
      "0": ["-1.4.4"],
      "1": ["+1.4.4"],
    },
    MAPPING_NOTES: "",
  },
};

const NewProjectModal: React.FC<NewProjectModalProps> = ({
  open,
  onClose,
  onCreate,
}) => {
  const [step, setStep] = useState(0);
  const [questionnaireAnswers, setQuestionnaireAnswers] = useState<
    Record<string, number | undefined>
  >({});
  const [newPipeline, setNewPipeline] = useState<Partial<PipelineData>>({
    client_id: 0,
    active: 1,
  });
  const [isCreating, setIsCreating] = useState(false);
  const [showContactUs, setShowContactUs] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "info" as "info" | "success" | "warning" | "error",
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewPipeline({ ...newPipeline, [e.target.name]: e.target.value });
  };

  const handleQuestionnaireAnswer = (answer: number) => {
    setQuestionnaireAnswers({ ...questionnaireAnswers, [step]: answer });
    if (step === 0 && answer === 0) {
      onClose();
      setSnackbar({
        open: true,
        message: "Process stopped. This application is not suitable.",
        severity: "info",
      });
    } else if (step === 0 && answer === 2) {
      setShowContactUs(true);
    } else {
      setStep(step + 1);
    }
  };

  const handleBack = () => {
    if (showContactUs) {
      setShowContactUs(false);
      setQuestionnaireAnswers({ ...questionnaireAnswers, 0: undefined });
    } else if (step > 0) {
      setStep(step - 1);
    }
  };

  const handleNext = () => {
    if (step === Object.keys(QuestionnaireData).length - 1) {
      setStep(step + 1);
      renderPipelineForm();
    } else {
      setStep(step + 1);
    }
  };

  const handleCreate = async () => {
    if (newPipeline) {
      setIsCreating(true);
      
      const filteredQuestionnaireAnswers: Record<string, number> = Object.fromEntries(
        Object.entries(questionnaireAnswers)
          .filter(([key, value]) => key !== "0" && value !== undefined)
          .map(([key, value]) => [key, value as number])
      );

      try {
        const createdPipeline = await createPipeline(
          newPipeline as PipelineData,
          filteredQuestionnaireAnswers
        );
        setSnackbar({
          open: true,
          message: "Pipeline created successfully",
          severity: "success",
        });
        onCreate(createdPipeline, filteredQuestionnaireAnswers);
        setTimeout(() => {
          onClose();
          // window.location.reload();
        }, 1000);
      } catch (error) {
        setSnackbar({
          open: true,
          message: "Failed to create pipeline. Please try again.",
          severity: "error",
        });
      } finally {
        setIsCreating(false);
      }
    }
  };

  const handleCloseSnackbar = (
    // event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const isFormValid = () => {
    const requiredFields: (keyof PipelineData)[] = [
      "client_id",
      "active",
      "codename",
      "company",
      "product_type",
      "start_date",
    ];
    return requiredFields.every(
      (field) => newPipeline[field] !== undefined && newPipeline[field] !== ""
    );
  };

  const isQuestionnaireComplete = () => {
    return Object.keys(QuestionnaireData).every(
      (key) => questionnaireAnswers[key] !== undefined
    );
  };

  const renderFirstQuestion = () => {
    const question = QuestionnaireData[0];
    return (
      <div>
        <Typography variant="subtitle1" style={{ marginBottom: "10px" }}>
          1. {question.CONTENT}
        </Typography>
        <Typography
          variant="body2"
          style={{ color: "grey", marginBottom: "20px" }}
        >
          {question.INSTRUCTION}
        </Typography>
        <DialogActions>
          <Button
            onClick={() => handleQuestionnaireAnswer(1)}
            variant={questionnaireAnswers[`0`] === 1 ? "contained" : "outlined"}
            color={questionnaireAnswers[`0`] === 1 ? "primary" : "inherit"}
          >
            Yes
          </Button>
          <Button
            onClick={() => handleQuestionnaireAnswer(0)}
            variant={questionnaireAnswers[`0`] === 0 ? "contained" : "outlined"}
            color={questionnaireAnswers[`0`] === 0 ? "primary" : "inherit"}
          >
            No
          </Button>
          <Button
            onClick={() => handleQuestionnaireAnswer(2)}
            variant={questionnaireAnswers[`0`] === 2 ? "contained" : "outlined"}
            color={questionnaireAnswers[`0`] === 2 ? "primary" : "inherit"}
          >
            Not Sure
          </Button>
        </DialogActions>
      </div>
    );
  };

  const renderOtherQuestions = () => {
    return (
      <div>
        {Object.keys(QuestionnaireData)
          .filter((key) => key !== "0")
          .map((key, index) => (
            <div key={index}>
              <Typography variant="subtitle1" style={{ marginBottom: "4px" }}>
                {parseInt(key) + 1}. {QuestionnaireData[key as keyof typeof QuestionnaireData].CONTENT}
              </Typography>
              <Typography
                variant="body2"
                style={{ color: "grey", marginBottom: "8px" }}
              >
                {QuestionnaireData[key as keyof typeof QuestionnaireData].INSTRUCTION}
              </Typography>
              <DialogActions>
                <Button
                  onClick={() => handleQuestionnaireAnswer(1)}
                  variant={
                    questionnaireAnswers[key] === 1 ? "contained" : "outlined"
                  }
                  color={
                    questionnaireAnswers[key] === 1 ? "primary" : "inherit"
                  }
                >
                  Yes
                </Button>
                <Button
                  onClick={() => handleQuestionnaireAnswer(0)}
                  variant={
                    questionnaireAnswers[key] === 0 ? "contained" : "outlined"
                  }
                  color={
                    questionnaireAnswers[key] === 0 ? "primary" : "inherit"
                  }
                >
                  No
                </Button>
              </DialogActions>
            </div>
          ))}
      </div>
    );
  };

  const renderContactUs = () => {
    return (
      <>
        <Typography variant="body1" style={{ marginBottom: "20px" }}>
          If you're not sure about the application's intended use or have any
          questions, please contact us for further assistance.
        </Typography>
        <DialogActions>
        <Button onClick={handleBack}>Back</Button>
        <Button
          variant="outlined"
          color="primary"
          style={{ float: "right" }}
          onClick={() => {
            onClose();
          }}
        >
          Contact Us
        </Button>
        </DialogActions>
      </>
    );
  };

  const renderPipelineForm = () => {
    return (
      <>
        <TextField
          margin="dense"
          name="active"
          label="Active"
          required={true}
          select
          fullWidth
          variant="outlined"
          value={newPipeline.active ?? 1}
          onChange={handleInputChange}
          InputProps={{ style: { height: 35 } }}
        >
          <MenuItem value={0}>Inactive</MenuItem>
          <MenuItem value={1}>Active</MenuItem>
        </TextField>
        <TextField
          margin="dense"
          name="active_notes"
          label="Active Notes"
          required={true}
          fullWidth
          variant="outlined"
          value={newPipeline.active_notes || ""}
          onChange={handleInputChange}
          InputProps={{ style: { height: 35 } }}
          placeholder="Enter active notes"
        />
        <TextField
          margin="dense"
          name="codename"
          label="Codename"
          required={true}
          fullWidth
          variant="outlined"
          value={newPipeline.codename || ""}
          onChange={handleInputChange}
          InputProps={{ style: { height: 35 } }}
          placeholder="Enter codename"
        />
        <TextField
          margin="dense"
          name="company"
          label="Company"
          required={true}
          fullWidth
          variant="outlined"
          value={newPipeline.company || ""}
          onChange={handleInputChange}
          InputProps={{ style: { height: 35 } }}
          placeholder="Enter company name"
        />
        <TextField
          margin="dense"
          name="product_type"
          label="Product Type"
          required={true}
          fullWidth
          variant="outlined"
          value={newPipeline.product_type || ""}
          onChange={handleInputChange}
          InputProps={{ style: { height: 35 } }}
          placeholder="Enter product type"
        />
        <TextField
          margin="dense"
          name="fda_history"
          label="FDA History"
          required={true}
          fullWidth
          variant="outlined"
          value={newPipeline.fda_history || ""}
          onChange={handleInputChange}
          InputProps={{ style: { height: 35 } }}
          placeholder="Enter FDA history"
        />
        <TextField
          margin="dense"
          name="start_date"
          label="Start Date"
          required={true}
          type="date"
          fullWidth
          variant="outlined"
          value={newPipeline.start_date || ""}
          onChange={handleInputChange}
          InputLabelProps={{
            shrink: true,
            style: { height: 35 },
          }}
        />
        <TextField
          margin="dense"
          name="compliance"
          label="Compliance"
          required={false}
          fullWidth
          variant="outlined"
          // value={newPipeline.compliance || ""}
          onChange={handleInputChange}
          InputProps={{ style: { height: 35 } }}
          placeholder="Enter compliance details"
        />
        <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
        <DialogActions>
          <Button onClick={handleBack}>Back</Button>
          <Button
            onClick={handleCreate}
            variant="outlined"
            color="primary"
            disabled={isCreating || !isFormValid()}
          >
            {isCreating ? "Creating..." : "Create"}
          </Button>
        </DialogActions>
      </>
    );
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>
          <Typography variant="h5" style={{ fontWeight: "bold" }}>
            {step === 0
              ? "First Question"
              : step === Object.keys(QuestionnaireData).length
              ? "New Pipeline"
              : "Questionnaire"}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {showContactUs
            ? renderContactUs()
            : step === 0
            ? renderFirstQuestion()
            : step === Object.keys(QuestionnaireData).length
            ? renderPipelineForm()
            : renderOtherQuestions()}
        </DialogContent>
        {step > 0 && step < Object.keys(QuestionnaireData).length && (
          <DialogActions>
            <Button onClick={handleBack}>Back</Button>
            <Button
              onClick={handleNext}
              disabled={
                Object.keys(QuestionnaireData).length - 1 === step ||
                !isQuestionnaireComplete()
              }
            >
              Next
            </Button>
          </DialogActions>
        )}
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => handleCloseSnackbar()}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => handleCloseSnackbar()}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default NewProjectModal;