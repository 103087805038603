import { useState, useEffect, useMemo } from "react";
import { useParams, useLocation } from "react-router-dom";
import {
  Typography,
  Button,
  Box,
  Container,
  Paper,
  Divider,
  Alert,
  CircularProgress,
} from "@mui/material";
import { getFile, getDocumentInsight } from "../services/documentService";
import mammoth from "mammoth";
import Chatbot from "../components/Chatbot";

const DEFAULT_DOCUMENT = {
  id: "default",
  name: "Default Document",
  type: "Unknown",
  size: "0 KB",
  content: "This is the default content for the document.",
  insights: "No insights are currently available for this document.",
};

const DocumentDetail = () => {
  const { documentId } = useParams();
  const location = useLocation();
  const [isDocument, setIsDocument] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [insights, setInsights] = useState<string>("");
  const [insightLoading, setInsightLoading] = useState(false);
  const [document, setDocument] = useState(() => ({
    ...DEFAULT_DOCUMENT,
    ...location.state?.document,
  }));
  const [fileContent, setFileContent] = useState<{
    type: string;
    data: any;
  } | null>(null);

  useEffect(() => {
    const fetchDocumentContent = async () => {
      if (documentId) {
        setLoading(true);
        setError(null);
        try {
          const fileData = await getFile(documentId);
          setFileContent(fileData);

          console.log("file data type:", fileData.type);

          if (fileData.type === "text") {
            setDocument((prevDoc: typeof DEFAULT_DOCUMENT) => ({
              ...prevDoc,
              content: fileData.data,
              type: "text",
            }));
          } else if (fileData.type === "doc") {
            console.log("file data 1:", fileData.data);

            const result = await mammoth.convertToHtml({
              arrayBuffer: fileData.data,
            });

            console.log("file data:", fileData.data);

            setDocument((prevDoc: typeof DEFAULT_DOCUMENT) => ({
              ...prevDoc,
              content: result.value,
              type: "doc",
            }));
            console.log("file data 2:", fileData.data);
          } else if (fileData.type === "pdf") {
            setDocument((prevDoc: typeof DEFAULT_DOCUMENT) => ({
              ...prevDoc,
              type: "pdf",
            }));
          } else if (fileData.type === "cfb") {
            setDocument((prevDoc: typeof DEFAULT_DOCUMENT) => ({
              ...prevDoc,
              content:
                "content" in fileData
                  ? fileData.content
                  : "No readable content found in this file",
              type: "text",
            }));
          }
        } catch (error) {
          console.error("Error fetching document content:", error);
          setError("Failed to fetch document. Please try again.");
        } finally {
          setLoading(false);
        }
      }
    };

    fetchDocumentContent();
  }, [documentId]);

  const module = location.state?.ectdCode || "";
  console.log("Module:", module);

  const fetchInsights = async () => {
    if (documentId) {
      setInsightLoading(true);
      setError(null);
      try {
        const data = await getDocumentInsight(documentId);

        // Specific parsing for compliance content
        const complianceRegex =
          /Content-Disposition: form-data; name="compliance"[\s\S]*?Content-Type: text\/plain\r?\n\r?\n(.*)\r?\n--/;
        const complianceMatch = data.match(complianceRegex);

        let complianceContent = complianceMatch
          ? complianceMatch[1].trim()
          : null;

        console.log("Parsed Compliance Content:", complianceContent);

        // Set insights based on compliance content
        if (complianceContent === "null" || !complianceContent) {
          setInsights(DEFAULT_DOCUMENT.insights);
        } else {
          setInsights(complianceContent);
        }
      } catch (error: any) {
        console.error("Error fetching insights:", error);
        setError(
          error.message || "Failed to fetch insights. Please try again."
        );
      } finally {
        setInsightLoading(false);
      }
    }
  };

  const handleSwitchView = () => {
    const newIsDocument = !isDocument;
    setIsDocument(newIsDocument);

    if (!newIsDocument && !insights) {
      fetchInsights();
    }
  };

  const pdfUrl = useMemo(() => {
    if (fileContent && fileContent.type === "pdf") {
      const pdfBlob = new Blob([fileContent.data], { type: "application/pdf" });
      return URL.createObjectURL(pdfBlob);
    }
    return null;
  }, [fileContent]);

  const renderContent = () => {
    if (loading) {
      return <CircularProgress />;
    }

    if (error) {
      return <Alert severity="error">{error}</Alert>;
    }

    if (!fileContent) {
      return <Typography>{document.content}</Typography>;
    }

    switch (fileContent.type) {
      case "pdf":
        if (!pdfUrl) {
          return (
            <Alert severity="error">
              Failed to process PDF. Please try again.
            </Alert>
          );
        }
        return (
          <>
            <object
              data={pdfUrl}
              type="application/pdf"
              width="100%"
              height="600px"
            >
              <Typography>
                Your browser does not support PDFs.
                <a href={pdfUrl} download={`${document.name}.pdf`}>
                  Download the PDF
                </a>
              </Typography>
            </object>
            <Button onClick={() => window.open(pdfUrl, "_blank")}>
              Open PDF in new tab
            </Button>
          </>
        );
      case "doc":
        return <div dangerouslySetInnerHTML={{ __html: document.content }} />;
      case "text":
      default:
        return <Typography>{document.content}</Typography>;
    }
  };

  const renderInsights = () => {
    if (insightLoading) {
      return (
        <Box sx={{ display: "flex", justifyContent: "center", p: 3 }}>
          <CircularProgress />
        </Box>
      );
    }

    if (error) {
      return <Alert severity="error">{error}</Alert>;
    }

    const displayInsights =
      typeof insights === "object"
        ? JSON.stringify(insights, null, 2)
        : insights;

    return (
      <Typography
        variant="body1"
        component="pre"
        sx={{
          whiteSpace: "pre-wrap",
          wordBreak: "break-word",
        }}
      >
        {displayInsights || "No insights available"}
      </Typography>
    );
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 14, mb: 2 }}>
      <Paper
        sx={{
          p: 3,
          borderRadius: 4,
          bgcolor: "rgba(255, 255, 255, 0.4)",
          backdropFilter: "blur(24px)",
          border: "1px solid",
          borderColor: "divider",
          boxShadow: `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1,
          }}
        >
          <Typography variant="h5">{document.name}</Typography>
          <Button
            variant="outlined"
            onClick={handleSwitchView}
            sx={{ fontWeight: "bold", width: 160 }}
          >
            Switch to {isDocument ? "Insights" : "Document"}
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography variant="h6">
            {isDocument ? "Document Content" : "Document Insights"}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Status: {document.processed_status} | Size: {document.size}M
          </Typography>
        </Box>
        <Divider sx={{ mx: -3 }} />
        <Box sx={{ mb: 2, mt: 2 }}>
          {isDocument ? renderContent() : renderInsights()}
        </Box>
      </Paper>
      <Chatbot />
    </Container>
  );
};

export default DocumentDetail;
