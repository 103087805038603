import { useState, useEffect } from 'react';
import { getECTD } from '../services/documentService';

interface SubModule {
  code: string;
  name: string;
  required: boolean;
  instructions: string;
}

interface ModuleData {
  [key: string]: SubModule[];
}

const useEctdData = (projectId: string) => {
  const [moduleData, setModuleData] = useState<ModuleData>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchModuleData = async () => {
      try {
        const response = await getECTD(projectId);
        if (response && response.length > 0) {
          const formatData = JSON.parse(response[0].format);
          const processedData = processModuleData(formatData);
          setModuleData(processedData);
        }
      } catch (error) {
        setError('Error fetching module data');
      } finally {
        setLoading(false);
      }
    };

    if (projectId) {
      fetchModuleData();
    }
  }, [projectId]);

  const processModuleData = (data: any): ModuleData => {
    const processedData: ModuleData = {};
    Object.keys(data).forEach((moduleKey) => {
      const module = data[moduleKey];
      processedData[moduleKey] = processSubsections(module);
    });
    return processedData;
  };

  const processSubsections = (subsections: any, parentCode: string = ''): SubModule[] => {
    const processedSubsections: SubModule[] = [];
    Object.keys(subsections).forEach((key) => {
      const subsection = subsections[key];
      const fullCode = parentCode ? `${key}` : key;
      if (subsection.SUBSECTIONS) {
        processedSubsections.push(...processSubsections(subsection.SUBSECTIONS, fullCode));
      } else {
        processedSubsections.push({
          code: fullCode,
          name: subsection.NAME,
          required: subsection.REQUIRED || false,
          instructions: subsection.INSTRUCTIONS || '',
        });
      }
    });
    return processedSubsections;
  };

  return { moduleData, loading, error };
};

export default useEctdData;