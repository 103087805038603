import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import logo from '../assets/logo.png';

const logoStyle = {
  width: '140px',
  height: 'auto',
};

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary">
      {'Copyright © '}
      <Link color="inherit" href="#">
        AX Watertown
      </Link>{' '}
      {new Date().getFullYear()}
    </Typography>
  );
}

export default function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: 'background.paper',
        py: 6,
      }}
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ mb: 4, mr: { md: 8 } }}>
            <img
              src= {logo}
              style={logoStyle}
              alt="logo"
            />
            <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
              Newsletter
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
              Subscribe to our newsletter for weekly updates and promotions.
            </Typography>
            <Box component="form" sx={{ display: 'flex' }}>
              <TextField
                size="small"
                placeholder="Your email address"
                sx={{ mr: 1, flexGrow: 1 }}
              />
              <Button variant="contained" size="small">
                Subscribe
              </Button>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 4 }}>
            {['Product', 'Company', 'Legal'].map((category) => (
              <Box key={category} sx={{ minWidth: 120 }}>
                <Typography variant="subtitle2" fontWeight="bold" gutterBottom>
                  {category}
                </Typography>
                {['Features', 'About us', 'Terms'].map((item) => (
                  <Link
                    key={item}
                    href="#"
                    color="text.secondary"
                    display="block"
                    sx={{ mb: 1 }}
                  >
                    {item}
                  </Link>
                ))}
              </Box>
            ))}
          </Box>
        </Box>
        <Box
          sx={{
            mt: 4,
            pt: 2,
            borderTop: '1px solid',
            borderColor: 'divider',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box>
            <Link href="#" color="text.secondary" sx={{ mr: 2 }}>
              Privacy Policy
            </Link>
            <Link href="#" color="text.secondary">
              Terms of Service
            </Link>
            <Copyright />
          </Box>
          <Stack direction="row" spacing={1}>
            <IconButton aria-label="GitHub" size="small">
              <GitHubIcon fontSize="small" />
            </IconButton>
            <IconButton aria-label="Twitter" size="small">
              <TwitterIcon fontSize="small" />
            </IconButton>
            <IconButton aria-label="LinkedIn" size="small">
              <LinkedInIcon fontSize="small" />
            </IconButton>
          </Stack>
        </Box>
      </Container>
    </Box>
  );
}