import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { useAuth0 } from "@auth0/auth0-react";
import theme from "./theme";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import SignIn from "./pages/SignIn";
import Signup from "./pages/Signup";
import Account from "./pages/Account";
import Box from "@mui/material/Box";
import ProjectsOverview from "./pages/ProjectsOverview";
import ProjectDetail from "./pages/ProjectDetail";
import DocumentDetail from "./pages/DocumentDetail";
import Chatbot from "./components/Chatbot";
import { ReactNode, useEffect } from "react";
import { MainHostname, SlaveHostname } from './assets/config.ts';

const ProtectedRoute = ({ children }: { children: ReactNode }) => {
  // const { isAuthenticated, isLoading } = useAuth0();
  // const location = useLocation();

  // if (isLoading) {
  //   return <div>Loading...</div>;
  // }

  // if (!isAuthenticated) {
  //   return <Navigate to="/signin" state={{ from: location }} replace />;
  // }

  return <>{children}</>;
};


const RouteMonitor = () => {
    const location = window.location;
    const routerLocation = useLocation();
    const navigate = useNavigate();
    const { isAuthenticated, isLoading } = useAuth0();

   
    useEffect(() => {
        // 每次路由发生变化时都会触发
        console.log("当前路由路径：", location);
        const { hostname, pathname }  = location
        // 你可以在这里执行其他逻辑，比如埋点、权限检查等
        if (hostname === MainHostname) {
          console.log('isAuthenticated 1', isAuthenticated, isLoading)
          if (pathname.startsWith('/project') || pathname.startsWith('/document') || pathname.startsWith('/signin')) {
           
            window.location.href = 'https://' + SlaveHostname + pathname
          }

        } else if (hostname === SlaveHostname) {
          console.log('isAuthenticated 2', isAuthenticated, isLoading)
          if ((pathname.startsWith('/project') || pathname.startsWith('/document') || pathname.startsWith('/signin'))) {
            if (!isAuthenticated) {
              navigate('/signin')
            }
         } else {
          window.location.href = 'https://' + MainHostname + pathname
         }
        } else if(hostname === 'localhost') {
          if (pathname.startsWith('/project') || pathname.startsWith('/document')) {
            console.log(' && !isLoading',isAuthenticated,  isLoading)
            if (!isAuthenticated) {
              navigate('/signin')
              return
            }
          }
            // window.location.href = 'https://' + slaveHostname + location.pathname
        }
    }, [routerLocation]);
    return null;
};


const Layout = ({ children }: { children: ReactNode }) => {
  const location = useLocation();
  
  const showChatbot = [
    '/project',
    '/project/',
  ].includes(location.pathname) || 
    location.pathname.startsWith('/project/') || 
    location.pathname.startsWith('/document/');

  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh", position: "relative" }}>
      <Header />
      <Box sx={{ flex: 1 }}>
        {children}
      </Box>
      {showChatbot && (
        <Box sx={{
          position: "fixed",
          bottom: "60px", 
          right: "20px",
          zIndex: 1000 
        }}>
          <Chatbot />
        </Box>
      )}
      <Footer />
    </Box>
  );
};

function App() {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
      <RouteMonitor />
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/account" element={
              <ProtectedRoute>
                <Account />
              </ProtectedRoute>
            } />
            <Route path="/project" element={
              <ProtectedRoute>
                <ProjectsOverview />
              </ProtectedRoute>
            } />
            <Route path="/project/:projectId" element={
              <ProtectedRoute>
                <ProjectDetail />
              </ProtectedRoute>
            } />
            <Route path="/document/:documentId" element={
              <ProtectedRoute>
                <DocumentDetail />
              </ProtectedRoute>
            } />
          </Routes>
        </Layout>
      </Router>
    </ThemeProvider>
  );
}

export default App;