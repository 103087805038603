import React, { useState } from "react";
import {
  Container,
  Typography,
  Button,
  Box,
  CssBaseline,
  TextField,
} from "@mui/material";

const Signup = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (email.trim()) {
      alert(`Demo account requested for ${email}. Message: ${message}`);
    } else {
      alert("Please enter a valid email address.");
    }
  };

  return (
    <Container maxWidth="sm">
      <CssBaseline />
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          marginTop: 16,
          marginBottom: 6,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Contact Us
        </Typography>
        <Typography variant="body1" sx={{ mt: 2, mb: 2 }}>
          Sign up is not open now. You can request a demo account by filling out the form below.
        </Typography>
        <TextField
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          margin="normal"
        />
        <TextField
          fullWidth
          id="message"
          label="Message (Optional)"
          name="message"
          multiline
          rows={4}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          margin="normal"
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Request Demo Account
        </Button>
      </Box>
    </Container>
  );
};

export default Signup;