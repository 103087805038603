import React, { useState, useCallback } from "react";
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  Select,
  MenuItem,
  Snackbar,
  CircularProgress,
  Divider,
  LinearProgress,
} from "@mui/material";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useDropzone } from "react-dropzone";
import { uploadFile } from "../services/documentService";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

interface FileUploadProps {
  projectId: string;
  onUploadSuccess: (fileNames: string[]) => void;
  onUploadError: (error: string) => void;
  moduleData: Record<string, SubModule[]>;
}

interface SubModule {
  code: string;
  name: string;
  required?: boolean;
}

const MultiFileUpload: React.FC<FileUploadProps> = ({
  projectId,
  onUploadSuccess,
  onUploadError,
  moduleData,
}) => {
  const [files, setFiles] = useState<File[]>([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [fileModules, setFileModules] = useState<
    Record<string, { mainModule: string; subModule: string }>
  >({});
  const [uploading, setUploading] = useState(false);
  const [message, setMessage] = useState<{
    text: string;
    severity: "success" | "error";
  } | null>(null);
  const [dialogState, setDialogState] = useState<'select' | 'progress'>('select');
  const [uploadProgress, setUploadProgress] = useState<Record<string, number>>({});

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setFiles(acceptedFiles);
    setOpenDialog(true);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "application/pdf": [".pdf"],
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
      "application/vnd.ms-excel": [".xls"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
    },
    multiple: true,
    maxSize: 50 * 1024 * 1024, // 50MB
  });

  const handleMainModuleChange = (fileName: string, mainModule: string) => {
    setFileModules((prev) => ({
      ...prev,
      [fileName]: { ...prev[fileName], mainModule, subModule: "" },
    }));
  };

  const handleSubModuleChange = (fileName: string, subModule: string) => {
    setFileModules((prev) => ({
      ...prev,
      [fileName]: { ...prev[fileName], subModule },
    }));
  };

  const handleUpload = async () => {
    setUploading(true);
    setDialogState('progress');
    setUploadProgress(
      files.reduce((acc, file) => ({ ...acc, [file.name]: 0 }), {})
    );
  
    try {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const { subModule } = fileModules[file.name] || {
          mainModule: "",
          subModule: "",
        };
        const ectd_code = subModule || "";  
        await uploadFile(projectId, file, ectd_code);
        
        setUploadProgress((prev) => ({ ...prev, [file.name]: 100 }));
      }
  
      setMessage({
        text: "All files uploaded successfully",
        severity: "success",
      });
      onUploadSuccess(files.map((file) => file.name));
      handleCloseDialog();
    } catch (error) {
      console.error("Upload error:", error);
      const errorMessage = error instanceof Error ? error.message : "Unknown error";
      setMessage({
        text: `Error uploading files: ${errorMessage}`,
        severity: "error",
      });
      onUploadError(errorMessage);
    } finally {
      setUploading(false);
    }
  };

  const handleCloseDialog = useCallback(() => {
    setOpenDialog(false);
    setTimeout(() => {
      setDialogState('select');
      setFiles([]);
      setFileModules({});
      setUploadProgress({});
    }, 300);
  }, []);

  const renderMainModuleOptions = () => {
    return Object.keys(moduleData).map((moduleKey) => (
      <MenuItem key={moduleKey} value={moduleKey}>
        {moduleKey}
      </MenuItem>
    ));
  };

  const renderSubModuleOptions = (mainModule: string) => {
    if (!mainModule || !moduleData[mainModule]) return null;

    return moduleData[mainModule].map((subModule) => (
      <MenuItem key={subModule.code} value={subModule.code}>
        {subModule.code} - {subModule.name}
        {subModule.required && (
          <span
          // style={{ color: "red" }}
          >
            {" "}
            *
          </span>
        )}
      </MenuItem>
    ));
  };

  return (
    <Box>
      <Box
        {...getRootProps()}
        sx={{
          border: "2px dashed",
          borderColor: isDragActive ? "primary.main" : "divider",
          borderRadius: 2,
          p: 3,
          textAlign: "center",
          bgcolor: "rgba(0, 0, 255, 0.04)",
          cursor: "pointer",
        }}
      >
        <input {...getInputProps()} />
        <Button
          variant="contained"
          component="span"
          startIcon={<CloudUploadIcon />}
          disabled={uploading}
          sx={{ width: 180, mb: 2 }}
        >
          {uploading ? <CircularProgress size={24} /> : "Choose files"}
        </Button>
        <Typography variant="h6" gutterBottom>
          Or Drag & drop files here
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Supported formats: PDF, DOC, DOCX, XLS, XLSX
        </Typography>
      </Box>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: {
            borderRadius: "5px",
            backgroundColor: "rgba(255, 255, 255, 0.9)",
            backdropFilter: "blur(24px)",
            border: "1px solid",
            borderColor: "divider",
            boxShadow:
              "0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)",
          },
        }}
      >
        <DialogTitle>
          {dialogState === 'select' ? "Please Select Modules for Each File" : "File Upload Progress"}
        </DialogTitle>
        <Divider />
        <DialogContent>
          {dialogState === 'select' ? (
            <List>
              {files.map((file, index) => (
                <ListItem key={index}>
                  <ListItemText
                    primary={file.name}
                    secondary={`${(file.size / 1024 / 1024).toFixed(2)} MB`}
                  />
                  <Select
                    value={fileModules[file.name]?.mainModule || ""}
                    onChange={(e) =>
                      handleMainModuleChange(file.name, e.target.value as string)
                    }
                    displayEmpty
                    sx={{ width: 200, mr: 2 }}
                  >
                    <MenuItem value="">
                      <em>Select main module</em>
                    </MenuItem>
                    {renderMainModuleOptions()}
                  </Select>
                  <Select
                    value={fileModules[file.name]?.subModule || ""}
                    onChange={(e) =>
                      handleSubModuleChange(file.name, e.target.value as string)
                    }
                    displayEmpty
                    sx={{ width: 200 }}
                    disabled={!fileModules[file.name]?.mainModule}
                  >
                    <MenuItem value="">
                      <em>Select sub-module</em>
                    </MenuItem>
                    {renderSubModuleOptions(fileModules[file.name]?.mainModule)}
                  </Select>
                </ListItem>
              ))}
            </List>
          ) : (
            <List>
              {files.map((file, index) => {
                const { mainModule, subModule } = fileModules[file.name] || {};
                const subModuleData = moduleData[mainModule]?.find(
                  (sm) => sm.code === subModule
                );
                return (
                  <ListItem key={index}>
                    <Box sx={{ width: '100%' }}>
                      <Typography variant="subtitle1">
                        {file.name}
                        {subModuleData && (
                          <Typography component="span" color="textSecondary">
                            {" - "}
                            {subModuleData.code} - {subModuleData.name}
                            {subModuleData.required && <span style={{ color: "red" }}> *</span>}
                          </Typography>
                        )}
                      </Typography>
                      <LinearProgress
                        variant="determinate"
                        value={uploadProgress[file.name] || 0}
                        sx={{ mt: 1, mb: 1 }}
                      />
                      <Typography variant="body2" color="textSecondary">
                        {uploadProgress[file.name]}%
                      </Typography>
                    </Box>
                  </ListItem>
                );
              })}
            </List>
          )}
        </DialogContent>
        <DialogActions>
          {dialogState === 'select' ? (
            <>
              <Button onClick={handleCloseDialog} color="primary">
                Cancel
              </Button>
              <Button
                onClick={handleUpload}
                color="primary"
                variant="contained"
                disabled={uploading}
              >
                {uploading ? <CircularProgress size={24} /> : "Upload"}
              </Button>
            </>
          ) : (
            <Button
              onClick={handleCloseDialog}
              color="primary"
              disabled={uploading}
            >
              Close
            </Button>
          )}
        </DialogActions>
      </Dialog>

      <Snackbar
        open={!!message}
        autoHideDuration={6000}
        onClose={() => setMessage(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={() => setMessage(null)} severity={message?.severity}>
          {message?.text}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default MultiFileUpload;
