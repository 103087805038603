import React, { useState, useEffect } from 'react';
import { 
  Grid, Card, CardContent, Typography, Box, LinearProgress, Avatar, IconButton, Button, Dialog, DialogTitle, DialogContent 
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import BiotechIcon from '@mui/icons-material/Biotech';
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { getPipelineList, deletePipeline, PipelineData } from '../services/documentService';
import EditProjectModal from './EditProjectModal';
import { Pipeline } from './ProjectStats';

interface ProjectCardsProps {
  searchTerm: string;
  pipelineList: Pipeline[],
  activeFilters: { stage: string },
  onProjectUpdate: () => void,
  onProjectDelete: () => void,
  showMessage: (message: string, severity: "success" | "error" | "info" | "warning") => void;
}

const colors = ['#3498db', '#e74c3c', '#2ecc71', '#f39c12', '#9b59b6', '#1abc9c', '#34495e'];

const getColor = (id: string) => {
  const index = parseInt(id.split('-')[1]) % colors.length;
  return colors[index];
};

const ProjectCards: React.FC<ProjectCardsProps> = ({ 
  searchTerm, 
  pipelineList,
  onProjectUpdate, 
  onProjectDelete, 
  showMessage,
}) => {
  const navigate = useNavigate();
  const [projects, setProjects] = useState<Pipeline[]>([]);
  const [editProject, setEditProject] = useState<Pipeline | null>(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState<{ open: boolean, projectId: string | null }>({ open: false, projectId: null });

  useEffect(() => {
    // fetchProjects();
    const formattedProjects = pipelineList.map((project) => ({
      ...project,
      color: getColor(project.id),
    }));
    setProjects(formattedProjects);
  }, [pipelineList]);

  const fetchProjects = async () => {
    try {
      const clientId = 0;
      const data = await getPipelineList(clientId);
      const formattedProjects = data.map((project: Pipeline) => ({
        ...project,
        color: getColor(project.id),
      }));
      setProjects(formattedProjects);
    } catch (error) {
      console.error('Error fetching projects:', error);
      showMessage('Error fetching projects', 'error');
    }
  };

  const handleUpdateProject = async () => {
    try {
      // await updatePipeline(pipelineId, updateData);
      onProjectUpdate();
      fetchProjects();
    } catch (error) {
      console.error("Error updating project:", error);
      showMessage('Error updating project', 'error');
    }
  };

  const handleDeleteProject = async (projectId: string) => {
    if (projectId) {
      try {
        await deletePipeline(projectId);
        onProjectDelete();
        setDeleteConfirmation({ open: false, projectId: null });
        fetchProjects();
      } catch (error) {
        console.error('Error deleting project:', error);
        showMessage('Error deleting project', 'error');
      }
    }
  };

  const filteredProjects = projects.filter(project => 
    project.codename.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const convertToPipelineData = (pipeline: Pipeline): PipelineData => ({
    ...pipeline,
    notes: '',
    format: '',
    client_id: 0,
    active_notes: '',
    compliance: '',
    fda_history: ''
  });

  return (
    <>
      <Grid container spacing={3}>
        {filteredProjects.map((project) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={project.id}>
            <Card 
              sx={{ 
                cursor: 'pointer', 
                transition: '0.3s', 
                '&:hover': { transform: 'translateY(-5px)', boxShadow: 3 },
                borderRadius: 2,
                overflow: 'visible',
                position: 'relative',
              }}
              onClick={() => {
                navigate(`/project/${project.id}`);
                window.scrollTo(0, 0);
              }}
            >
              <Box 
                sx={{ 
                  height: 8, 
                  backgroundColor: project.color, 
                  position: 'absolute', 
                  top: 0, 
                  left: 0, 
                  right: 0, 
                  borderTopLeftRadius: 8, 
                  borderTopRightRadius: 8 
                }} 
              />
              <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                    {project.start_date}
                  </Typography>
                  <Box>
                    <IconButton 
                      onClick={(e) => { 
                        e.stopPropagation(); 
                        setEditProject(project); 
                      }}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                    <IconButton 
                      onClick={(e) => { 
                        e.stopPropagation(); 
                        setDeleteConfirmation({ open: true, projectId: project.id }); 
                      }}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Box>
                </Box>
                <Typography variant="h6" component="div" sx={{ mb: 1 }}>
                  {project.codename}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <LinearProgress 
                    variant="determinate" 
                    value={0} 
                    sx={{ flexGrow: 1, mr: 1, height: 6, borderRadius: 3 }}
                  />
                  <Typography variant="body2">0%</Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography variant="body2" color="text.secondary">
                    {project.product_type}
                  </Typography>
                  <Avatar sx={{ width: 24, height: 24, fontSize: '0.75rem', bgcolor: project.color }}>
                    <BiotechIcon fontSize="small" />
                  </Avatar>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Edit Project Modal */}
      <EditProjectModal
        open={editProject !== null}
        onClose={() => setEditProject(null)}
        pipeline={editProject ? convertToPipelineData(editProject) : null}
        onUpdate={() => {
          handleUpdateProject();
          setEditProject(null);
        }}
      />

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteConfirmation.open}
        onClose={() => setDeleteConfirmation({ open: false, projectId: null })}
      >
        <DialogTitle>Delete Pipeline</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this project?</Typography>
          <Button 
            onClick={() => {
              if (deleteConfirmation.projectId) {
                handleDeleteProject(deleteConfirmation.projectId);
              } else {
                showMessage('No project selected for deletion', 'error');
              }
            }}
            variant="contained" 
            color="error"
            sx={{ mt: 2 }}
          >
            Delete
          </Button>
          <Button 
            onClick={() => setDeleteConfirmation({ open: false, projectId: null })} 
            variant="outlined" 
            sx={{ mt: 2, ml: 2 }}
          >
            Cancel
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ProjectCards;