import axios from "axios";
import * as CFB from 'cfb';

const API_URL = "https://client-document-process.axwatertown.workers.dev";
const CLIENT_API_URL = "https://client-data.axwatertown.workers.dev/Pipeline/";
const CHATBOT_API_URL = "https://website-chatbot.azurewebsites.net/api/chatbot";
const module_insight = 'https://module-insight.axwatertown.workers.dev/'
// const document_insight = 'https://pharma-doc-analysis.azurewebsites.net/api/Analyzer'
const document_insight = 'https://9db2ecb3-client-data.axwatertown.workers.dev/Document/file/'

export interface PipelineData {
  id: string;
  client_id: number;
  active: number;
  codename: string;
  company: string;
  product_type: string;
  active_notes: string;
  compliance: string | null;
  fda_history: string | null;
  notes: string | null;
  start_date: string;
  format: string | null;
  color: string | null;
}

interface UpdateData {
  ectd_code: string;
}

const axiosInstance = axios.create({
  baseURL: process.env.NODE_ENV === 'production' 
    ? '/' 
    : 'http://localhost:5173' 
});

export const createPipeline = async (pipelineData: Partial<PipelineData>, questionnaireData: Record<string, number>) => {
  try {
    const response = await axios.post(CLIENT_API_URL, null, {
      params: {
        field: JSON.stringify(pipelineData),
        questionnaire: JSON.stringify(questionnaireData)
      },
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error creating pipeline:', error);
    throw error;
  }
};

export const fetchDocuments = async (id: string) => {
  try {
    const response = await axiosInstance.get("https://client-data.axwatertown.workers.dev/Document/list/", {
      params: { id },
      headers: {
        'Content-Type': 'application/json',
      }
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching documents:", error);
    throw error;
  }
};

export const updatePipeline = async (
  pipelineId: string,
  updateData: Partial<PipelineData>
) => {
  try {
    const { id, client_id, format, color, ...filteredData } = updateData;
    const response = await axios.put(CLIENT_API_URL, null, {
      params: {
        id: pipelineId,
        field: JSON.stringify(filteredData),
      },
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error updating pipeline:", error);
    throw error;
  }
};

export const getPipelineList = async (clientId: number) => {
  try {
    const response = await axiosInstance.get(
      "https://client-data.axwatertown.workers.dev/Pipeline/list/",
      {
        params: { id: clientId || 0 },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching pipeline list:", error);
    throw error;
  }
};

export const deletePipeline = async (pipelineId: string) => {
  try {
    const response = await axios.delete(`${CLIENT_API_URL}`, {
      params: { id: pipelineId },
    });
    return response.data;
  } catch (error) {
    console.error("Error deleting pipeline:", error);
    throw error;
  }
};

export const getECTD = async (pipelineId: string) => {
  try {
    const response = await axios.get("https://client-data.axwatertown.workers.dev/Pipeline/detail/", {
      params: { id: pipelineId },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching eCTD:", error);
    throw error;
  }
};

export const uploadFile = async (
  pipelineId: string,
  file: File,
  ectd_code?: string
): Promise<any> => {
  const formData = new FormData();
  formData.append("id", pipelineId);
  formData.append("file", file);

  if (ectd_code) {
    formData.append("ectd_code", ectd_code);
  }

  try {
    const response = await axios.post(API_URL, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error uploading file:", error);
    if (axios.isAxiosError(error)) {
      const errorMessage = error.response?.data?.message || error.message;
      throw new Error(`Failed to upload file: ${errorMessage}`);
    }
    throw error;
  }
};

export const getFile = async (documentId: string) => {
  try {
    const response = await axios.get(
      "https://client-data.axwatertown.workers.dev/Document/file/",
      {
        params: { id: documentId },
        responseType: "arraybuffer",
      }
    );

    const contentType = response.headers["content-type"];
    const data = response.data;

    if (contentType === "application/pdf") {
      return {
        type: "pdf",
        data: data,
      };
    } else if (
      contentType === "application/msword" ||
      contentType ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      return {
        type: "doc",
        data: data,
      };
    } if (contentType === "application/x-cfb") {
      return processCFBFile(data);
    } else {
      try {
        const textDecoder = new TextDecoder("utf-8");
        return {
          type: "text",
          data: textDecoder.decode(data),
        };
      } catch (decodeError) {
        console.error("Text Decoding Error:", decodeError);
        return {
          type: "binary",
          data: data,
        };
      }
    }
  } catch (error) {
    console.error("File Fetch Error:", error);
    throw error;
  }
};

interface CFBResult {
  type: "cfb" | "binary";
  data: ArrayBuffer;
  content?: string;
  streams?: Record<string, string>;
  entries?: CFB.CFB$Container;
  error?: string;
}

const processCFBFile = (data: ArrayBuffer | Uint8Array): CFBResult => {
  try {
    // 1. Convert input to Uint8Array for CFB processing
    const uint8Array = data instanceof Uint8Array ? data : new Uint8Array(data);

    // 2. Basic validation
    if (uint8Array.length < 8) {
      throw new Error('Invalid CFB file: File too small');
    }

    // 3. Read the CFB file
    let cfbData: CFB.CFB$Container;
    try {
      // Use Uint8Array directly with CFB.read
      cfbData = CFB.read(uint8Array, { type: 'array' });
    } catch (error) {
      throw new Error(`CFB parsing failed: ${error instanceof Error ? error.message : 'Unknown error'}`);
    }

    // 4. Extract content from known streams
    const streamContents: Record<string, string> = {};
    let bestContent = '';

    // Process root directory entries
    if (cfbData && Array.isArray(cfbData.FullPaths)) {
      for (let i = 0; i < cfbData.FullPaths.length; i++) {
        try {
          const entry = cfbData.FileIndex[i];
          if (!entry || entry.type !== 2) continue; // Skip if not a file

          const buffer = entry.content;
          if (!buffer || !(buffer instanceof Uint8Array)) continue;

          // Try different encodings
          for (const encoding of ['utf-16le', 'utf-8', 'ascii']) {
            try {
              const decoder = new TextDecoder(encoding);
              const decodedContent = decoder.decode(buffer).trim();
              
              if (decodedContent.length > 0) {
                const streamName = entry.name || `Stream${i}`;
                streamContents[streamName] = decodedContent;
                
                // Keep the longest valid content as our best result
                if (decodedContent.length > bestContent.length) {
                  bestContent = decodedContent;
                }
                break;
              }
            } catch (e) {
              continue; // Try next encoding
            }
          }
        } catch (e) {
          console.debug(`Failed to process entry ${i}:`, e);
          continue;
        }
      }
    }

    // 5. Handle results
    if (Object.keys(streamContents).length === 0) {
      return {
        type: "binary",
        data: uint8Array.buffer,
        error: 'No readable content found'
      };
    }

    return {
      type: "cfb",
      data: uint8Array.buffer,
      content: bestContent,
      streams: streamContents,
      entries: cfbData
    };

  } catch (error) {
    console.error("CFB Processing Error:", error);
    // Ensure we always return an ArrayBuffer
    const finalBuffer = data instanceof ArrayBuffer ? data : 
                       data instanceof Uint8Array ? data.buffer :
                       new ArrayBuffer(0);
    return {
      type: "binary",
      data: finalBuffer,
      error: error instanceof Error ? error.message : 'Unknown CFB processing error'
    };
  }
};

export const updateFile = async (id:string, updateData:UpdateData) => {
  try {
    const response = await axios.put("https://client-data.axwatertown.workers.dev/Document/", null, {
      params: {
        id,
        field: JSON.stringify(updateData),
      },
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error updating file:", error);
    throw error;
  }
}

export const deleteFile = async (documentId: string) => {
  try {
    const response = await axios.delete(
      "https://client-data.axwatertown.workers.dev/Document/",
      {
        params: { id: documentId },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting file:", error);
    throw error;
  }
};

export const chatWithBot = async (sessionId: string, userMessage: string) => {
  try {
    const response = await axios.post(CHATBOT_API_URL, null, {
      params: {
        code: "mwvZX4HWJPlS48nEV5ubhvbVnuE5zFwhxED9AnEXVW6GAzFuw6dN9w==",
        session_id: sessionId,
        user_message: userMessage,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error chatting with bot:", error);
    if (axios.isAxiosError(error)) {
      const errorMessage = error.response?.data || error.message;
      throw new Error(`Chatbot API error: ${errorMessage}`);
    }
    throw error;
  }
};

export const getModuleInsight = async (pipelineId: string, module: number) => {
  const params = new URLSearchParams({
    id: pipelineId,
    module: module.toString()
  });

  const response = await axios.post(`${module_insight}?${params.toString()}`);
  return response;
};

// export const getDocumentInsight = async (id: string, module: string) => {
//   const url = `${document_insight}?code=el9GPxDMuKtgJC7ww8EUY1QG4lxKDMltze8cN_SURNGzAzFurcZmmA==&id=${id}&module=${module}`;
  
//   try {
//     const response = await axios({
//       method: 'post',
//       url,
//       headers: {
//         'Content-Type': 'application/json'
//       }
//     });
    
//     return response.data;
//   } catch (error: any) {
//     console.error("Error fetching document insight:", error);
//     throw new Error(error.message || "Failed to fetch document insights");
//   }
// };

export const getDocumentInsight = async (id: string) => {
  const url = `${document_insight}?id=${id}`;
  
  try {
    const response = await axios({
      method: 'get',
      url,
      headers: {
        'Content-Type': 'application/json'
      }
    });
    
    return response.data;
  } catch (error: any) {
    console.error("Error fetching document insight:", error);
    throw new Error(error.message || "Failed to fetch document insights");
  }
};