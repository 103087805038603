import { useState, useEffect, useMemo } from "react";
import { Typography, Box } from "@mui/material";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { fetchDocuments } from "../services/documentService";
import useEctdData from "../components/useEctdData";
import FolderIcon from "@mui/icons-material/Folder";
import { Pipeline } from "./ProjectStats";
import { getRedictUrl } from "../assets/config";
import { useAuth0 } from "@auth0/auth0-react";
import message from "./Message";

const TimelineContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
}));

const TimelineItem = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "200px 1fr",
  gap: theme.spacing(2),
  alignItems: "center",
  padding: theme.spacing(2, 0),
  "&:not(:last-child)": {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

const ProjectLabel = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0.5, 1.5),
  borderRadius: "25px",
  backgroundColor: theme.palette.grey[100],
  cursor: "pointer",
  "&:hover": {
    backgroundColor: theme.palette.grey[200],
  },
  "& .project-id": {
    width: 28,
    height: 28,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: theme.spacing(1.5),
    color: theme.palette.common.white,
    fontWeight: "bold",
  },
}));

const ProgressBarContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  height: "60px",
  backgroundColor: theme.palette.grey[50],
  borderRadius: "30px",
  overflow: "hidden",
}));

const BaseProgressBar = styled(Box)<{
  width: number;
  color: string;
  opacity: number;
  left: number;
}>`
  position: absolute;
  height: 100%;
  left: ${(props) => props.left}%;
  width: ${(props) => Math.max(props.width, 20)}%;
  background-color: ${(props) => props.color};
  opacity: ${(props) => props.opacity};
  border-radius: 30px;
  display: flex;
  align-items: center;
  padding-left: 15px;
  color: white;
  font-weight: bold;
  transition: width 0.3s ease-in-out;
`;

const TotalProgressBar = styled(BaseProgressBar)`
  z-index: 1;
`;

const RequiredProgressBar = styled(BaseProgressBar)`
  z-index: 2;
`;

const ProgressText = styled(Typography)`
  position: absolute;
  right: 15px;
  color: white;
  font-weight: bold;
  z-index: 3;
`;

const FileInfoText = styled(Typography)`
  font-size: 0.75rem;
  line-height: 1.2;
  color: white;
  z-index: 3;
`;

const IconWrapper = styled(Box)`
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3;
`;

const ProjectTimeline = (props: { pipelineList: Pipeline[] }) => {
  const [projects, setProjects] = useState<Pipeline[]>([]);

  useEffect(() => {
    const pipelineList = Array.isArray(props.pipelineList) ? props.pipelineList : [];
      setProjects(
        pipelineList.map((project, index) => ({
          ...project,
          color: getColor(project.id),
          index,
        }))
      );
  }, [props.pipelineList]);

  return (
    <TimelineContainer>
      {projects.map((project) => (
        <ProjectTimelineItem key={project.id} project={project} />
      ))}
    </TimelineContainer>
  );
};

const ProjectTimelineItem = ({ project }: { project: Pipeline }) => {
  const { moduleData } = useEctdData(project.id);
  const [progress, setProgress] = useState<{
    totalFiles: number;
    uploadedFiles: number;
    totalRequiredFiles: number;
    uploadedRequiredFiles: number;
  } | null>(null);
  const navigate = useNavigate();
  const memoizedModuleData = useMemo(() => moduleData, [moduleData]);


  useEffect(() => {
    const calculateProjectProgress = async () => {
      if (!moduleData) {
        console.log(`No module data for project ${project.id}`);
        return;
      }

      const documents = await fetchDocuments(project.id);

      const calculatedProgress = {
        totalFiles: 0,
        uploadedFiles: 0,
        totalRequiredFiles: 0,
        uploadedRequiredFiles: 0,
      };

      Object.entries(moduleData).forEach(([moduleKey, moduleItems]) => {
        const moduleNumber = moduleKey.charAt(1);
        const moduleDocuments = documents.filter(
          (doc: { ectd_code: string }) => doc.ectd_code && doc.ectd_code.startsWith(moduleNumber + ".")
        );

        calculatedProgress.totalFiles += moduleItems.length;
        calculatedProgress.uploadedFiles += moduleDocuments.length;

        const requiredItems = moduleItems.filter((item) => item.required);
        calculatedProgress.totalRequiredFiles += requiredItems.length;

        calculatedProgress.uploadedRequiredFiles += moduleDocuments.filter(
          (doc: { ectd_code: string }) => {
            const ectdCode = doc.ectd_code.split("/").pop();
            return requiredItems.some((item) => item.code === ectdCode);
          }
        ).length;
      });
      setProgress(calculatedProgress);
    };

    if (moduleData) {
      calculateProjectProgress();
    }
  }, [project.id, memoizedModuleData]);

  const calculateTotalProgress = () => {
    if (!progress) {
      return { totalPercentage: 0, requiredPercentage: 0 };
    }

    const totalPercentage =
      (progress.uploadedFiles / progress.totalFiles) * 100 || 0;
    const requiredPercentage =
      (progress.uploadedRequiredFiles / progress.totalRequiredFiles) * 100 || 0;
    return { totalPercentage, requiredPercentage };
  };

  const { totalPercentage, requiredPercentage } = calculateTotalProgress();
  const { isAuthenticated } = useAuth0();

  const handleProjectClick = () => {
    if (!isAuthenticated) {
      message.error({ content: "Please login to view projects" });
      return
    }
    navigate(getRedictUrl(`/project/${project.id}`));
    window.scrollTo(0, 0);
  };

  const getAdjustedWidth = (percentage: number) => {
    return 25 + percentage * 0.75;
  };

  const getStartPosition = (index: number) => {
    return 0 + index * 2;
  };

  return (
    <TimelineItem>
      <ProjectLabel onClick={handleProjectClick}>
        <span className="project-id" style={{ backgroundColor: project.color }}>
          {project.id.split("-")[1]}
        </span>
        <Box>
          <Typography variant="subtitle1" fontWeight="bold">
            {project.codename}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            {project.product_type}
          </Typography>
        </Box>
      </ProjectLabel>
      <ProgressBarContainer>
        <TotalProgressBar
          width={getAdjustedWidth(totalPercentage) + 10} // Add 10 to make the total progress bar slightly wider
          color={project.color}
          opacity={0.35}
          left={getStartPosition(project.index)}
        >
          <ProgressText
            style={{
              position: 'absolute',
              top: '50%',
              right: '10px',
              transform: 'translateY(-50%)',
              fontWeight: 'bold',
              fontSize: '1.5rem',
            }}
          >
            {requiredPercentage.toFixed(0)}%
          </ProgressText>
        </TotalProgressBar>
        <RequiredProgressBar
          width={getAdjustedWidth(requiredPercentage)}
          color={project.color}
          opacity={0.7}
          left={getStartPosition(project.index)}
        >
          <IconWrapper>
            <FolderIcon />
          </IconWrapper>
          <Box ml={4}>
            <FileInfoText>
              Total: {progress?.uploadedFiles}/{progress?.totalFiles}
            </FileInfoText>
            <FileInfoText>
              Required: {progress?.uploadedRequiredFiles}/
              {progress?.totalRequiredFiles}
            </FileInfoText>
          </Box>
          <ProgressText
            style={{
              position: 'absolute',
              top: '50%',
              right: '10px',
              transform: 'translateY(-50%)',
              fontWeight: 'bold',
              fontSize: '1.5rem',
            }}
          >
            {totalPercentage.toFixed(0)}%
          </ProgressText>
        </RequiredProgressBar>
      </ProgressBarContainer>
    </TimelineItem>
  );
};

const getColor = (id: string) => {
  const colors = [
    "#3498db",
    "#e74c3c",
    "#2ecc71",
    "#f39c12",
    "#9b59b6",
    "#1abc9c",
    "#34495e",
  ];
  const index = parseInt(id.split("-")[1]) % colors.length;
  return colors[index];
};

export default ProjectTimeline;
